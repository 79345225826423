import * as React from "react";
import { graphql } from "gatsby";

import { Query, ContentfulPrint } from "../graphql-types";
import PageContainer from "../components/page-container";
import Title from "../components/title";
import Layout from "../components/layout";
import PageHeader from "../components/page-header";
import Blocks from "../components/content-blocks";
import SEO from "../components/seo";

interface Props {
  data: Query;
}

function getDescription(print: ContentfulPrint) {
  const textBlock = print.blocks.find(block =>
    Boolean(block.content && block.content.childMarkdownRemark)
  );

  if (textBlock) {
    return textBlock.content.childMarkdownRemark.excerpt;
  }

  return "";
}

export default function Print({ data }: Props) {
  const print = data.contentfulPrint;
  const seo = print.seo || {
    id: "",
    title: print.title,
    description: getDescription(print)
  };
  return (
    <Layout>
      <SEO data={seo} />
      <PageContainer>
        <PageHeader>
          <Title>{print.title}</Title>
        </PageHeader>
        {print.blocks && <Blocks blocks={print.blocks} />}
      </PageContainer>
    </Layout>
  );
}

export const query = graphql`
  query Print($slug: String!) {
    contentfulPrint(slug: { eq: $slug }) {
      title
      seo {
        ...SEO
      }
      blocks {
        id
        size
        color {
          value
        }
        image {
          fluid(maxWidth: 1400, quality: 80) {
            ...GatsbyContentfulFluid
          }
        }
        content {
          childMarkdownRemark {
            html
          }
        }
        titleColor {
          value
        }
        textColor {
          value
        }
        slider {
          id
          fluid(maxWidth: 1400, quality: 80) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;
